import { Controller } from "@hotwired/stimulus";
'use strict'
import {PageFlip} from 'page-flip';

export default class extends Controller {
  connect() {
    const pageFlip = new PageFlip(document.getElementById('book'),
      {
          size: "stretch", // or "fixed"
          minWidth: 315,
          maxWidth: 1600,
          minHeight: 420,
          maxHeight: 2160,
          showCover: true,
          width: 400,
          height: 600,
      }
    );

    pageFlip.loadFromImages([
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-01.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-02.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-03.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-04.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-05.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-06.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-07.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-08.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-09.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-10.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-11.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-12.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-13.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-14.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-15.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-16.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-17.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-18.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-19.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-20.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-21.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-22.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-23.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-24.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-25.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-26.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-27.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-28.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-29.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-30.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-31.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-32.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-33.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-34.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-35.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-36.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-37.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-38.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-39.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-40.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-41.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-42.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-43.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-44.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-45.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-46.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-47.jpg',
      'https://pazienti-platform.s3.eu-central-1.amazonaws.com/pop-health/2023-01/Pazienti-Pop-Health-2023-01-48.jpg',
    ]);
  }
}
