export class SearchDoctor {
  constructor() {
    this.forms = document.querySelectorAll('.pz-doctor-search');
    this.hasOfficeVisitClass = document.querySelector('.pz-doctor-search') && document.querySelector('.pz-doctor-search').classList.contains('office_visit')

    console.log('Component SearchDoctor', !!this.forms);

    this.init();
  }

  init() {
    if (this.forms.length > 0) {
      this.attachEvents();
      this.checkParameters();
    }
  }

  attachEvents() {
    this.forms.forEach((form) => {
      form.addEventListener('submit', (e) => {
        e.preventDefault();
        let speciality = form.querySelector('#speciality').value;
        let region_and_province = form.querySelector('#province').value;
        let vet_search = form.getAttribute('data-vet');

        let path = '';
        if (this.hasOfficeVisitClass == true) {
          path = '/cerca-specialita/' + speciality + '/' + region_and_province;
        } else {
          path = '/specialita/' + speciality + '/' + region_and_province;
        }
        path += vet_search ? '#pets' : '';
        window.location = path;
      });
    });
  }

  checkParameters() {
    let url = new URL(document.location);
    let params = url.hash;

    if (params == "#pets") {
      let targetTabs = document.querySelectorAll(".tabs li");

      targetTabs.forEach((el) => {
        el.querySelector("input").checked = false;
      });

      document.querySelector(".veterinario input").checked = "checked";
    }
  }
}
