import { CityUpdater } from './cityUpdater';

export class RegistrationPage {
  constructor() {
    const body = document.body;
    const isDoctorRegisterPage = body.classList.contains('static_pages') && body.classList.contains('doctors_register');
    const isUserRegistrationPage = body.classList.contains('users_registrations') && body.classList.contains('new');
    const isPartnerRegistrationPage =
      body.classList.contains('partners_registrations') && (
        body.classList.contains('alleati_per_la_salute') ||
        body.classList.contains('mondino')
      );
    const isLiviDoctorRegisterPage =
      body.classList.contains('static_pages') && body.classList.contains('doctors_register_liviconnect');
    const isHealthFacilityDealUserRegisterPage =
      body.classList.contains('health_facility_deals') && body.classList.contains('new_user_registration');

    console.log(
      'Component RegistrationPage',
      isDoctorRegisterPage || isUserRegistrationPage || isPartnerRegistrationPage || isLiviDoctorRegisterPage
    );

    if (
      isDoctorRegisterPage ||
      isUserRegistrationPage ||
      isPartnerRegistrationPage ||
      isLiviDoctorRegisterPage ||
      isHealthFacilityDealUserRegisterPage
    ) {
      this.CityUpdater = new CityUpdater();
    }
  }
}
