export class BookVcSidebarCard {
  setTarget(uri) {
    console.log('Component BookVcSidebarCard', uri);

    this.enableButton(uri);
  }

  enableButton(uri) {
    let buttons = document.querySelectorAll('.pz-card--book-vc a.button');

    console.log('Component BookVcSidebarCard', !!buttons.length);

    for (let button of buttons) {
      button.setAttribute('href', uri);
      button.addEventListener('click', (event) => this.clickButton(event));
      button.classList.remove('button--hidden');
    }
  }

  clickButton(event) {
    var track_url = event.target.attributes.before.value;
    fetch(track_url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
      },
    })
      .then((res) => res.json())
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  }
}
