import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['aside', 'index']
  update = () => {
    const weight = this.element.weight.value
    const height = this.element.height.value
    const age = this.element.age.value
    const sex = this.element.sex.value
    const bmiIndex = Math.round(weight / height / height * 100000) / 10
    const increment = [18, 25, 35, 45, 55, 65].findLastIndex(n => n <= age)
    const scales = {
      male: [0, 20, 25, 30, 40],
      female: [0, 19, 24, 29, 39]
    }
    const index = scales[sex].findLastIndex(n => (n + increment) <= bmiIndex)
    this.indexTargets[index].value = bmiIndex
    const bmiClass = ['sottopeso', 'normopeso', 'sovrappeso', 'obesita', 'obesita-grave'][index]
    this.asideTargets.forEach((aside, i) => aside.hidden = !(index === i))
  }
}
