import { Flash } from './flash.js';

export class Badge {
  constructor() {
    let clipboardables = document.querySelectorAll('.pz-badge--clipboardable');
    clipboardables.forEach((clipboardable) =>
      clipboardable.addEventListener('click', (event) => {
        this.copyToClipboard(event.target);
      })
    );
  }

  dismiss(el) {
    fetch(el.dataset.dismiss, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        let flash = new Flash();

        if (response.status == 'success') {
          el.remove();
          flash.set('success', 'Elemento rimosso con successo');
        } else {
          flash.set('error', 'Si è verificato un errore generico');
        }
      })
      .catch((error) => console.error(error));
  }

  copyToClipboard(el) {
    navigator.clipboard.writeText(el.text);
    let flash = new Flash();
    flash.set('success', 'Il coupon è stato copiato negli appunti');
  }
}
