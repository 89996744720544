import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'firstName',
    'lastName',
    'professionId',
    'provinceId',
    'cityId',
    'email',
    'password',
    'privacyPolicy',
    'contractPolicy',
    'submitButton',
  ];

  validateForm() {
    if (this.allFieldsHaveValue()) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  allFieldsHaveValue() {
    const firstName = this.firstNameTarget.value;
    const lastName = this.lastNameTarget.value;
    const email = this.emailTarget.value;
    const password = this.passwordTarget.value;
    const professionId = this.professionIdTarget.value;
    const provinceId = this.provinceIdTarget.value;
    const cityId = this.cityIdTarget.value;
    const privacyPolicy = this.privacyPolicyTarget;
    const contractPolicy = this.contractPolicyTarget;

    console.log('Province Id:', provinceId, 'City Id:', cityId);

    return (
      firstName != null &&
      firstName != '' &&
      lastName != null &&
      lastName != '' &&
      professionId != null &&
      professionId != '' &&
      provinceId != null &&
      provinceId != '' &&
      cityId != null &&
      cityId != '' &&
      email != null &&
      email != '' &&
      password != null &&
      password != '' &&
      privacyPolicy.checked &&
      contractPolicy.checked
    );
  }

  resetCityIdOnChangeProvinceId() {
    this.cityIdTarget.value = '';
    console.log('New City Id Value is: ', this.cityIdTarget.value);
  }

  enableButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableButton() {
    this.submitButtonTarget.disabled = true;
  }
}
