import Swiper from "swiper";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "background", "container", "next", "prev", "pagination", "captions", "texts", "excerpt", "readmore"];
  connect() {
    const setTitles = this.setTitles;
    const captions = this.captionsTarget;
    const texts = this.textsTarget;
    const excerpt = this.excerptTarget;
    const readmore = this.readmoreTarget;
    const controller = this;
    this.swiper = new Swiper(this.containerTarget, {
      history: {
        key: this.element.dataset.gallerySlug,
        replaceState: true,
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      slidesPerView: 1,
      pagination: {
        el: this.paginationTarget,
        clickable: false,
        type: 'fraction'
      },
      slideClass: 'swiper-slide',
      on: {
        afterInit: swiper => {
          const toOpen = location.pathname.endsWith(swiper.slides[swiper.activeIndex].dataset.history)
          if(toOpen) {
            this.pathname = location.pathname.split('/'+this.gallerySlug())[0]
            this.open()
          }
        },
        slideChangeTransitionStart: function (swiper) {
          setTitles(this, captions, texts, excerpt, readmore);
        },
        slideChangeTransitionEnd: function (swiper) {
        },
        hashChange: function(swiper) {
          // debugger
        },
        hashSet: function(swiper) {
          controller.handleOpen()
          controller.pushPageView()
        }
      }
    });
  }

  pushPageView() {
    window.dataLayer = window.dataLayer || [];
    const payload = {
      'event': 'pageView',
      'pageUrl': location.href,
      'pageTitle': this.excerptTarget.innerText
    }
    console.debug({payload, push: window.dataLayer.push(payload)});
  }

  setTitles(swiper, captionsTarget, textsTarget, excerptTarget, readmoreTarget) {
    const slideCaption = swiper.slides[swiper.activeIndex].dataset.caption;
    const slideText = swiper.slides[swiper.activeIndex].dataset.text;
    const slideExcerpt = swiper.slides[swiper.activeIndex].dataset.excerpt;
    captionsTarget.innerHTML = decodeURIComponent(slideCaption);
    textsTarget.innerHTML = decodeURIComponent(slideText);
    excerptTarget.innerHTML = decodeURIComponent(slideExcerpt);
    readmoreTarget.checked = false;
  }

  handleOpen(event) {
    this.pathname = location.pathname
    this.addSlugs()
    this.open()
    this.pushPageView()
  }

  addSlugs() {
    const pathname = [location.pathname, this.gallerySlug(), this.swiper.slides[0].dataset.history].join('/').replaceAll('/\/+/', '/')
    history.replaceState(null, null, pathname)
  }

  gallerySlug() {
    return this.element.dataset.gallerySlug
  }

  open() {
    if(!this.modalTarget.classList.contains("modal-open")) {
      this.modalTarget.classList.add("modal-open");
      this.backgroundTarget.classList.add("modal-open");
      document.body.style.overflow = "hidden"; // do not scroll body
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.modalTarget.classList.remove("modal-open");
    this.backgroundTarget.classList.remove("modal-open");
    document.body.style.overflow = "scroll"; // do not scroll body
    history.replaceState(null, null, this.pathname)
  }
}
