'use strict';

export class DropdownMenu {
  constructor() {
    this.navItem = document.querySelectorAll('.pz-navbar_item');
    this.dropdown = document.querySelectorAll('.pz-navbar__container--dropdown');
    this.header = document.querySelector('.pz-navbar');

    this.init();
  }

  init() {
    const navItem = [...this.navItem];
    const dropdown = [...this.dropdown];
    const header = this.header;

    navItem.forEach((item) => {
      item.addEventListener('mouseover', function () {
        dropdown.forEach((dropdownContainer) => {
          if (dropdownContainer.id === this.id) {
            dropdownContainer.classList.remove('pz-navbar--hidden');
            dropdownContainer.classList.add('slide-animation-down');

            header.addEventListener('mouseleave', function () {
              setTimeout(() => {
                dropdownContainer.classList.add('pz-navbar--hidden');
              }, 1500);
            });
          } else {
            dropdownContainer.classList.add('pz-navbar--hidden');
          }
        });
      });
    });
  }
}
