export class HeaderMenu {
  constructor() {
    const $mainHeader = $('.main-header');
    if ($mainHeader.length > 0) {
      this.init($mainHeader);
    }
  }

  init($mainHeader) {
    const $menu = $('.main-menu', $mainHeader);
    const $trigger = $('.main-header-top__menu-trigger', $mainHeader);

    $trigger.on('click', () => {
      $mainHeader.addClass('transition-enabled');
      $menu.one('transitionend', () => $mainHeader.removeClass('transition-enabled'));
      $('body').toggleClass('noscroll-mobile', !$mainHeader.hasClass('menu-open'));
      $mainHeader.toggleClass('menu-open');
    });
  }
}