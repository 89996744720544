import Swiper from "swiper";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "next", "prev", "pagination", "captions", "texts", "excerpt", "readmore"];

  connect() {
    const setTitles = this.setTitles;
    const captions = this.captionsTarget;
    const texts = this.textsTarget;
    const excerpt = this.excerptTarget;
    const readmore = this.readmoreTarget;
    this.swiper = new Swiper(this.containerTarget, {
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      slidesPerView: 1,
      pagination: {
        el: this.paginationTarget,
        clickable: false,
        type: 'fraction'
      },
      slideClass: 'swiper-slide',
      on: {
        slideChangeTransitionStart: function () {
          setTitles(this, captions, texts, excerpt, readmore);
        }
      }
    });
  }

  setTitles(swiper, captionsTarget, textsTarget, excerptTarget, readmoreTarget) {
      const slideCaption = swiper.slides[swiper.activeIndex].dataset.caption;
      const slideText = swiper.slides[swiper.activeIndex].dataset.text;
      const slideExcerpt = swiper.slides[swiper.activeIndex].dataset.excerpt;
      captionsTarget.innerHTML = decodeURIComponent(slideCaption);
      textsTarget.innerHTML = decodeURIComponent(slideText);
      excerptTarget.innerHTML = decodeURIComponent(slideExcerpt);
      readmoreTarget.checked = false;
  }
}
