export class DrugMonography {
  constructor() {
    this.drugIndex = document.querySelectorAll('.drugs__index ul li a');
    this.sections = document.querySelectorAll('.monography__section');

    console.log('Component DrugMonography', !!this.drugIndex.length);

    this.init();
  }

  init() {
    if (this.drugIndex.length == 0) return;
    this.addIndexEvents();
    this.addAccordionEvents();
    this.preselectSection();
  }

  resetSections(section = '') {
    for (let item of this.drugIndex) {
      item.parentElement.classList.remove('drugs__index__item--active');
    }

    for (let innerSection of this.sections) {
      if (innerSection != section) {
        innerSection.classList.add('monography--hidden');
      }
    }
  }

  preselectSection() {
    let sectionHash = window.location.hash.substr(1);
    if ('' == sectionHash) return false;
    this.resetSections();
    let anchor = document.querySelector('#drugsIndex a[href="#' + sectionHash + '"]');
    let monography = document.getElementById(sectionHash);
    anchor.parentElement.classList.add('drugs__index__item--active');
    monography.classList.remove('monography--hidden');
  }

  addIndexEvents() {
    for (let item of this.drugIndex) {
      item.addEventListener('click', (event) => {
        event.preventDefault();
        let target = event.currentTarget;
        this.resetSections();
        target.parentElement.classList.add('drugs__index__item--active');
        document.querySelector(target.getAttribute('href')).classList.remove('monography--hidden');
      });
    }
  }

  addAccordionEvents() {
    for (let item of document.querySelectorAll('.monography__title')) {
      item.addEventListener('click', (event) => {
        event.preventDefault();
        let target = event.currentTarget;
        let section = target.parentElement;

        this.resetSections(section);

        document
          .querySelector('.drugs__index a[href="#' + section.id + '"]')
          .parentElement.classList.add('drugs__index__item--active');

        if (section.classList.contains('monography--hidden')) {
          section.classList.remove('monography--hidden');
        } else {
          section.classList.add('monography--hidden');
        }
      });
    }
  }
}
