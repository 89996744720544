import autoComplete from '@tarekraafat/autocomplete.js/dist/js/autoComplete.js';

export class AutoComplete {
  constructor(options = {}) {
    this.autoComplete = autoComplete;
    this.threshold = options.threshold || 3;
  }

  baseAutocompleteObject(autoCompleteItem) {
    return {
      data: {
        src: async () => {
          const query = autoCompleteItem.value;
          const source = await fetch(`${autoCompleteItem.dataset.src}?q=${query}`);
          const data = await source.json();
          return data.items;
        },
        key: ['title'],
        cache: false,
      },
      placeHolder: autoCompleteItem.dataset.placeholder,
      selector: `#${autoCompleteItem.id}`,
      threshold: this.threshold,
      resultsList: {
        render: true,
        container: (source) => {
          source.setAttribute('id', `${autoCompleteItem.id}ResultsList`);
          source.setAttribute('class', `pz-autocomplete__results`);
        },
        destination: autoCompleteItem,
        position: 'afterend',
        element: 'ul',
      },
      maxResults: 5,
      highlight: true,
      resultItem: {
        content: (data, source) => {
          source.innerHTML = data.match;
        },
        element: 'li',
      },
    };
  }
}
