import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submitButton', 'phoneNumber', 'firstName', 'lastName'];

  validateForm() {
    if (this.allFieldsHaveValue()) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  allFieldsHaveValue() {
    const phoneNumber = this.phoneNumberTarget.value;
    const firstName = this.firstNameTarget.value;
    const lastName = this.lastNameTarget.value;

    return (
      phoneNumber != null &&
      phoneNumber != '' &&
      firstName != null &&
      firstName != '' &&
      lastName != null &&
      lastName != ''
    );
  }

  enableButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableButton() {
    this.submitButtonTarget.disabled = true;
  }
}
