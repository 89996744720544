export class Collapsible {
  constructor(store) {
    this.store = store;

    this.init();
  }

  init() {
    let collapsibleAnchors = document.querySelectorAll('.pz-collapsible__link');

    console.log('Component Collapsible', !!collapsibleAnchors.length);

    for (let collapsibleAnchor of collapsibleAnchors) {
      collapsibleAnchor.addEventListener('click', () => {
        let target = event.currentTarget;
        let wrapper = target.parentElement;

        wrapper.classList.toggle('pz-collapsible--collapsed');
      });
    }
  }
}
