import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['id', 'button'];

  connect() {
    this.id = this.idTarget.innerHTML;
    this.button = this.buttonTarget;
    this.url = this.buttonTarget.dataset.url;
    this.askNotificationPermission();
    this.checkButtonStatus();
    this.checker = setInterval(() => {
      this.checkButtonStatus();
    }, 10000);
  }

  checkButtonStatus() {
    fetch(this.url)
      .then((res) => res.json())
      .then((response) => {
        if (response['enable_room_button?']) {
          this.button.querySelector('a').classList.remove('disabled');
          new Notification('Hey, hai una room di customer care tra 5 minuti!');
          clearInterval(this.checker);
        } else {
          this.button.querySelector('a').classList.add('disabled');
        }
      });
  }

  askNotificationPermission() {
    if (!('Notification' in window)) {
      // alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // var notification = new Notification("Hi there!");
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          new Notification('Bene, riceverai una notifica 5 minuti prima di una room!');
        }
      });
    }
  }
}
