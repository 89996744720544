'use strict';

export class DropdownMobileMenu {
  constructor() {
    this.arrowDown = document.querySelectorAll('.pazienti-icon-arrow--down--mb');
    this.arrowUp = document.querySelectorAll('.pazienti-icon-arrow--up--mb');
    this.dropdown = document.querySelectorAll('.pz-mega-menu-dropdown');

    this.init();
  }

  init() {
    const arrowDown = [...this.arrowDown];
    const arrowUp = [...this.arrowUp];
    const dropdown = [...this.dropdown];

    arrowDown.forEach((arrow) => {
      arrow.addEventListener('click', function () {
        dropdown.forEach((dropdownContainer) => {
          if (dropdownContainer.id === this.id) {
            dropdownContainer.classList.remove('pz-mega-menu--hidden');
            dropdownContainer.classList.add('slide-animation-down');
          } else {
            dropdownContainer.classList.add('pz-mega-menu--hidden');
          }
        });

        arrowUp.forEach((arrow) => {
          if (arrow.id === this.id) {
            this.classList.add('hidden');
            arrow.classList.remove('hidden');
          } else {
            arrow.classList.add('hidden');
          }
        });

        arrowDown.forEach((arrow) => {
          if (arrow.id !== this.id) {
            arrow.classList.remove('hidden');
          }
        });
      });
    });

    arrowUp.forEach((arrow) => {
      arrow.addEventListener('click', function () {
        dropdown.forEach((dropdownContainer) => {
          if (dropdownContainer.id === this.id) {
            this.classList.add('hidden');
            dropdownContainer.classList.add('pz-mega-menu--hidden');
          }
        });
        arrowDown.forEach((arrow) => {
          if (arrow.id === this.id) {
            arrow.classList.remove('hidden');
          }
        });
      });
    });
  }
}
