export class AccordionRow {
  constructor(store) {
    this.store = store;

    this.init();
  }

  init() {
    let collapsibleAnchors = document.querySelectorAll('.pz-accordion-row__link');

    console.log('Component AccordionRow', !!collapsibleAnchors.length);

    for (let collapsibleAnchor of collapsibleAnchors) {
      collapsibleAnchor.addEventListener('click', () => {
        let target = event.currentTarget;
        let wrapper = target.parentElement;
        $(wrapper).find('.pz-accordion-row__body').slideToggle();
        $(wrapper).find('.pazienti_icon-caret_up').toggle();
        $(wrapper).find('.pazienti_icon-caret_down').toggle();
      });
    }
  }
}
