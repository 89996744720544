import { Flash } from './flash.js';
import MicroModal from 'micromodal';

export class Rating {
  create(event) {
    event.preventDefault();
    let form = event.currentTarget;
    let formData = new FormData(form);
    let flash = new Flash();

    let score = document.querySelector('[name="score"]:checked');
    score = score ? parseInt(score.value) : 0;
    formData.append('rating[score]', score);

    fetch(form.action, {
      method: form.method,
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if ('success' == response.status) {
          if (gaReviewData != null) {
            gtag('event', gaReviewData.eventAction, {
              event_category: gaReviewData.eventCategory,
              event_label: gaReviewData.eventLabel,
            });
          }

          flash.set('success', 'Recensione inviata correttamente.');
          MicroModal.close('new_rating');
        } else {
          let message_keys = Object.keys(response.message);

          for (let message_key of message_keys) {
            for (let message_text of response.message[message_key]) {
              flash.set('warning', message_text);
            }
          }

          this.enableSubmitElement(form);
        }
      })
      .catch((error) => {
        flash.set('error', 'Si è verificato un errore generico.');
        this.enableSubmitElement(form);
        console.error(error);
      });
  }

  enableSubmitElement(form) {
    $.rails.enableElement(form.querySelector('button[type=submit]'));
  }
}
