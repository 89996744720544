import { offset } from './../helpers/offset.js';

export class AnchorsHandler {
  constructor() {
    this.init();
  }

  init() {
    if ('' != window.location.hash) {
      setTimeout(() => {
        window.scroll({
          top:
            offset(document.querySelector(window.location.hash)).top -
            document.querySelector('.pz2024 .main-header-top').offsetHeight,
          behavior: 'smooth',
        });
      }, 50);
    }

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();
        let target = e.currentTarget;
        let href = target.getAttribute('href');
        if ('#' == href) return false;
        history.pushState(null, null, window.location.pathname + href);

        setTimeout(() => {
          window.scroll({
            top: offset(document.querySelector(href)).top - document.querySelector('.pz2024 .main-header-top').offsetHeight,
            behavior: 'smooth',
          });
        }, 50);

        if (anchor.classList.contains('animateTarget')) {
          setTimeout(() => {
            document.querySelector(href).classList.add('animated', 'heartBeat');
          }, 50);

          setTimeout(() => {
            document.querySelector(href).classList.remove('animated', 'heartBeat');
          }, 1000);
        }
      });
    });
  }
}
