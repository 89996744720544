import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['fullPrice', 'discountedPrice', 'discountPercentage'];

  resetDiscountedPrice() {
    if (this.checkDiscountPercentageHasValue()) {
      this.setDiscountedPrice();
    }
  }

  resetDiscountPercentage() {
    if (this.checkDiscountedPriceHasValue()) {
      this.setDiscountPercentage();
    }
  }

  checkDiscountedPriceHasValue() {
    const discountedPrice = this.discountedPriceTarget.value;

    return discountedPrice != null && discountedPrice != '';
  }

  checkDiscountPercentageHasValue() {
    const discountPercentage = this.discountPercentageTarget.value;

    return discountPercentage != null && discountPercentage != '';
  }

  setDiscountedPrice() {
    this.discountedPriceTarget.value = this.discountedPriceAmount();
  }

  setDiscountPercentage() {
    this.discountPercentageTarget.value = this.discountPercentage();
  }

  fullPriceAmount() {
    return parseFloat(this.fullPriceTarget.value).toFixed(2);
  }

  discountedPriceAmount() {
    let discountedAmount = (parseFloat(this.discountPercentageTarget.value).toFixed(2) * this.fullPriceAmount()) / 100;
    let discountedPrice = this.fullPriceAmount() - discountedAmount;
    return parseFloat(discountedPrice).toFixed(2);
  }

  discountPercentage() {
    let totalDiscount = this.fullPriceAmount() - parseFloat(this.discountedPriceTarget.value).toFixed(2);
    let discountPercentage = (totalDiscount * 100) / this.fullPriceAmount();
    return parseFloat(discountPercentage).toFixed(2);
  }
}
