export class AccountDropdown {
  constructor() {
    this.init();
  }

  disableDropdown(anchor) {
    anchor.addEventListener('click', () => {
      event.preventDefault();
      event.stopPropagation();
      let dropdown = document.querySelector('.pz-dropdown-container .pz-dropdown');
      let target = event.currentTarget;
      let wrapper = target.closest('.pz-navbar__account');

      if (null != wrapper) {
        wrapper.classList.remove('pz-navbar__account--active');
      }

      dropdown.classList.remove('pz-dropdown--visible');
      target.parentNode.querySelector('.pz-dropdown-anchor').style.display = 'block';
      target.style.display = 'none';
    });
  }

  init() {
    const anchorsClose = document.getElementsByClassName('pz-dropdown-anchor-close');

    console.log('Component AccountDropdown', !!anchorsClose.length);

    for (let anchor of anchorsClose) {
      this.disableDropdown(anchor);
    }
  }
}
