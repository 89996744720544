import { Controller } from '@hotwired/stimulus';
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = ['province', 'speciality', 'health_facility_deal_kind', 'health_facility'];

  refreshSearch(e) {
    const form = e.target.closest('form');
    const url = form.attributes.action.value;
    const params = this.extractFormParams(form);

    document.querySelector('#searchResults').style.opacity = '0.3';

    Rails.ajax({
      type: 'post',
      dataType: 'script',
      url: url,
      data: params,
    });
  }

  resetFilters(e) {
    e.preventDefault();
    const form = e.target.closest('form');
    const url = form.attributes.action.value;
    const params = this.extractFormParams(form, { nullifyValues: true });

    document.querySelector('#searchResults').style.opacity = '0.3';

    Rails.ajax({
      type: 'post',
      dataType: 'script',
      url: url,
      data: params,
    });
  }

  extractFormParams(form, options = {}) {
    return [].filter
      .call(form.elements, function (el) {
        return typeof el.checked === 'undefined' || el.checked;
      })
      .filter(function (el) {
        return !!el.name;
      })
      .map(function (el) {
        let str = encodeURIComponent(el.name) + '=';
        if (!options.nullifyValues) {
          str = str + encodeURIComponent(el.value);
        }
        return str;
      })
      .join('&');
  }
}
