import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'submitButton',
    'password',
    'passwordConfirmation',
    'pazientiPrivacyPolicy',
    'novartisPrivacyPolicy',
  ];

  validateForm() {
    if (this.allFieldsHaveValue()) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  allFieldsHaveValue() {
    const password = this.passwordTarget.value;
    const passwordConfirmation = this.passwordConfirmationTarget.value;
    const pazientiPrivacyPolicy = this.pazientiPrivacyPolicyTarget;

    return (
      password != null &&
      password != '' &&
      passwordConfirmation != null &&
      passwordConfirmation != '' &&
      pazientiPrivacyPolicy.checked
    );
  }

  validateNovartisHasValue() {
    this.disableButton();
    const novartisPrivacyPolicy = this.novartisPrivacyPolicyTarget;
    if (!novartisPrivacyPolicy.checked) {
      this.disableButton();
    } else {
      this.enableButton();
      this.validateForm();
    }
  }

  enableButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableButton() {
    this.submitButtonTarget.disabled = true;
  }
}
