export class Previewable {
  constructor() {
    this.previewables = document.querySelectorAll('.pz-previewable');
    console.log('Component Previewable', !!this.previewables.length);
    this.init();
  }

  init() {
    for (let previewable of this.previewables) {
      let fullContent = previewable.querySelector('.pz-previewable__full-content');
      let triggers = previewable.querySelectorAll('.pz-previewable__toggle');
      console.log('Triggers:', triggers);
      for (let trigger of triggers) {
        trigger.addEventListener('click', (e) => {
          console.log('Triggered!');
          e.preventDefault();
          fullContent.classList.toggle('hidden');
        });
      }
    }
  }
}
