/**
 * This class handles suggested items and tracking user visits to trackable items.
 *
 * It can be used in two ways:
 * - if an element is setup with the `j-trackable` class and the `trackableUrl`
 *   data attribute, it will only load suggested contents from the URL and
 *   display them in the matching element (see the corresponding `.js.erb` file
 *   for the target div where contents will be displayed);
 * - if an element is setup with the `j-trackable` class and the `trackableUrl`,
 *   `trackableId` and `trackableName` data attributes, it will both track the
 *   visit to the trackable element in a session cookie AND load suggested contents
 *   from the URL.
 */
export class Trackable {
  /**
   * @param {any} cookies is a Cookie access wrapper. It must implement `get(cookieName)` and `set(cookieName, value)` methods.
   * @param {any} rails is used to do a post request and execute the returned js code.
   * @param {any} user is used to authenticate the user to save their navigation information on the server
   */
  constructor(cookies, rails, user) {
    this.trackables = document.querySelectorAll('.j-trackable')
    this.cookies = cookies
    this.rails = rails
    this.user = user

    console.log('Component Trackable', !!this.trackables.length)

    // this.init()
  }

  init() {
    for (let trackable of this.trackables) {
      if (this.userHasGivenConsent()) {

        this.trackVisitOnCookie(trackable)
        if (this.user.isLogged()) {
          this.trackVisitOnServer(this.user, trackable);
        }
      }
      this.getSuggestedItems(trackable)
    }
  }

  userHasGivenConsent() {
    const TRACKING_AND_ADV_CONSENT_CATEGORY = 5
    return !(
      _iub.cs === undefined ||
      _iub.cs.consent.timestamp === undefined ||
      _iub.cs.consent.purposes[TRACKING_AND_ADV_CONSENT_CATEGORY] === undefined
    )
  }

  /**
   * Track user view of `trackable` in the corresponding tracking cookie.
   *
   * @param {any} trackable the element to be tracked. It must have the
   *              `trackableId` and `trackableName` data attributes.
   */
  trackVisitOnCookie(trackable) {
    const trackableId = trackable.dataset.trackableId
    const trackableName = trackable.dataset.trackableName

    const cookieName = 'trackings-' + trackableName
    const cookieValue = this.cookies.get(cookieName)
    let trackings = []

    if (cookieValue) {
      trackings = JSON.parse(cookieValue)
    }

    if (trackings.indexOf(trackableId) != -1) {
      trackings = trackings.filter(function (el) {
        return el != trackableId
      })
    }

    trackings = trackings.concat(trackableId)

    if (trackings.length > 5) {
      trackings.shift()
    }

    this.cookies.set(cookieName, JSON.stringify(trackings))
  }

  /**
   * @param {any} user the currently logged user. Must respond to the `token()` method.
   * @param {any} trackable the object being tracked. It must have a `serverTrackingUrl`
   *              data property for the server side tracking to complete successfully.
   */
  trackVisitOnServer(user, trackable) {
    const trackableId = trackable.dataset.trackableId
    const trackableName = trackable.dataset.trackableName
    const serverTrackingUrl = trackable.dataset.serverTrackingUrl

    const token = user.token()

    fetch(serverTrackingUrl, {
      method: 'post',
      body: JSON.stringify({ resource: { resource_id: trackableId, resource_type: trackableName } }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
        'Authorization': `Bearer ${token}`
      },
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data);
    });
  }

  /**
   * Depending on the trackable type, call the matching AJAX endpoint to populate suggested items.

   * @param {any} trackable the element holding the URL of the suggested items endpoint.
   */
  getSuggestedItems(trackable) {
    const suggestionUrl    = trackable.dataset.trackableUrl
    const suggestionTarget = trackable.dataset.trackableTarget

    // const spinner          = document.querySelector(suggestionTarget).querySelector('.fa-circle-notch');

    this.rails.ajax({
      type: 'POST',
      url: suggestionUrl,
      data: this.suggestionData(),
      success: (response) => {
        // if (spinner) {
        //   spinner.style.display = 'none';
        // }
        console.log('success!')
      },
    })
  }

  suggestionData() {
    const latest_seen_post_ids = JSON.parse(this.cookies.get('trackings-Post') || '[]')
    const latest_seen_content_ids = JSON.parse(this.cookies.get('trackings-Content') || '[]')
    const latest_seen_question_ids = JSON.parse(this.cookies.get('trackings-Question') || '[]')
    return (
      'latest_seen_post_ids=' +
      latest_seen_post_ids.join(',') +
      '&latest_seen_content_ids=' +
      latest_seen_content_ids.join(',') +
      '&latest_seen_question_ids=' +
      latest_seen_question_ids.join(',')
    )
  }

}
