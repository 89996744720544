import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'submitButton',
    'billingName',
    'billingEmail',
    'billingAddressProvinceId',
    'billingAddressCityId',
    'billingAddressLine1',
    'billingAddressPostalCode',
    'billingTaxCode',
    'billingPhone',
  ];

  connect() {
    this.validateForm();
  }

  validateForm() {
    if (this.allFieldsHaveValue()) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  allFieldsHaveValue() {
    const billingName = this.billingNameTarget.value;
    const billingEmail = this.billingEmailTarget.value;
    const billingAddressProvinceId = this.billingAddressProvinceIdTarget.value;
    const billingAddressCityId = this.billingAddressCityIdTarget.value;
    const billingAddressLine1 = this.billingAddressLine1Target.value;
    const billingAddressPostalCode = this.billingAddressPostalCodeTarget.value;
    const billingTaxCode = this.billingTaxCodeTarget.value;
    const billingPhone = this.billingPhoneTarget.value;

    return (
      billingName &&
      billingEmail &&
      billingAddressProvinceId &&
      billingAddressCityId &&
      billingAddressLine1 &&
      billingAddressPostalCode &&
      billingTaxCode &&
      billingPhone
    );
  }

  enableButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableButton() {
    this.submitButtonTarget.disabled = true;
  }
}
