import { AutoComplete } from './autoComplete.js';

export class AutoCompleteCities extends AutoComplete {
  constructor() {
    super({ threshold: 2 });
    this.init();
  }

  init() {
    console.log('Component AutoCompleteCities', !!document.querySelectorAll('.pz-autocomplete--cities').length);

    document.querySelectorAll('.pz-autocomplete--cities').forEach((autoCompleteItem, index) => {
      let autoCompleteObject = Object.assign(
        {
          onSelection: (feedback) => {
            let selectionId = feedback.selection.value.id;
            let selectionTitle = feedback.selection.value.name;
            autoCompleteItem.value = selectionTitle;
            document.querySelector(autoCompleteItem.dataset.onSelectionTarget).value = selectionId;
          },
        },
        super.baseAutocompleteObject(autoCompleteItem)
      );

      new this.autoComplete(autoCompleteObject);
    });
  }
}
