export class RelatedContent {
  constructor(rails, cookies) {
    this.rails = rails;
    this.cookies = cookies;

    this.hooks = [...document.querySelectorAll('.related-content')].reduce((acc, el) => {
      let url = el.dataset.relatedContentUrl;
      let data = { id: el.id, render: el.dataset.relatedContentRender, count: el.dataset.relatedContentCount };

      return { ...acc, [url]: [...(acc[url] || []), data] };
    }, {});
    console.log('Component RelatedContent');
    this.init();
  }

  init() {
    for (let url of Object.keys(this.hooks)) {
      this.getRelatedContent(url, this.hooks[url]);
    }
  }

  getRelatedContent(url, configs) {
    const query = new URLSearchParams();
    for (let config of configs) {
      for (const [key, value] of Object.entries(config)) {
        query.append(`configs[][${key}]`, value);
      }
    }
    this.rails.ajax({
      type: 'GET',
      url: url,
      data: query.toString(),
      success: (response) => {},
    });
  }
}
