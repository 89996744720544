export class Filters {
  toggle(el, target) {
    console.log('Component Filters toggle');

    let open = el.querySelector('.pz-more-filters__open');
    let close = el.querySelector('.pz-more-filters__close');
    let targetEl = document.querySelector(target);
    open.classList.toggle('pz-filters-hidden');
    close.classList.toggle('pz-filters-hidden');
    targetEl.classList.toggle('pz-filters-hidden');
  }
}
