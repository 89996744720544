export class HeaderSearch {
  constructor() {
    const $mainHeader = $('.main-header');
    if ($mainHeader.length > 0) {
      this.init($mainHeader);
    }
  }

  init($mainHeader) {
    const $form = $('.main-header-search__form', $mainHeader);
    const $input = $('input', $form);
    const $trigger = $('.main-header-search__trigger', $mainHeader);

    $trigger.on('click', () => {
      $form.addClass('active');
      $input.focus();
    });

    $input.on('blur', () => $form.removeClass('active'));
  }
}
