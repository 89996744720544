import { parse, format } from 'date-fns';

export class SearchVc {
  constructor() {
    this.wrapper = document.querySelector('.pz-search-vc');
    this.prev = undefined;
    this.step = 0;

    console.log('Component SearchVc', !!this.wrapper);

    this.init();
  }

  validateFields(speciality_id, date = null, hour = null) {
    let validationSuccess = true;

    for (let el of [speciality_id, date, hour].filter((el) => el != null)) {
      el.parentElement.classList.remove('shake');

      if ('' == el.value) {
        validationSuccess = false;

        window.requestAnimationFrame(() => {
          el.parentElement.classList.add('animated', 'shake');
        });
      }
    }

    return validationSuccess;
  }

  convertDate(inputDateString) {
    let inputDate = parse(inputDateString, 'dd/mm/yyyy', new Date());
    return format(inputDate, 'dd-mm-yyyy');
  }

  init() {
    if (undefined == this.wrapper) {
      return;
    }

    this.prev = this.wrapper.querySelector('.pz-search-vc__button--prev');

    if (0 == this.step) {
      this.prev.style.display = 'none';
    }

    this.wrapper.querySelector('.pz-search-vc__button--desktop').addEventListener('click', () => {
      event.preventDefault();

      let speciality_id = this.wrapper.querySelector('#speciality_id');
      let date = this.wrapper.querySelector('#date');
      let hour = this.wrapper.querySelector('#hour');

      if (this.validateFields(speciality_id, date, hour)) {
        let formattedDate = this.convertDate(date.value);
        let webAppUrl = document.querySelector('.pz-search-vc').dataset.webAppUrl;
        window.location.href = `${webAppUrl}/search/${formattedDate}/${hour.value}/${speciality_id.value}/-/-/-/-`;
        return;
      }
    });

    this.wrapper.querySelector('.pz-search-vc__button--mobile').addEventListener('click', () => {
      event.preventDefault();

      let speciality_id = this.wrapper.querySelector('#speciality_id');
      let date = this.wrapper.querySelector('#date');
      let hour = this.wrapper.querySelector('#hour');

      switch (this.step) {
        case 0:
          if (this.validateFields(speciality_id)) {
            speciality_id.parentElement.style.display = 'none';
            date.parentElement.style.display = 'flex';
            this.prev.style.display = 'flex';
            this.step = 1;
          }
          break;
        case 1:
          if (this.validateFields(speciality_id, date)) {
            date.parentElement.style.display = 'none';
            hour.parentElement.style.display = 'flex';
            this.step = 2;
          }
          break;
        case 2:
          if (this.validateFields(speciality_id, date, hour)) {
            let formattedDate = this.convertDate(date.value);
            let webAppUrl = document.querySelector('.pz-search-vc').dataset.webAppUrl;
            window.location.href = `${webAppUrl}/search/${formattedDate}/${hour.value}/${speciality_id.value}/-/-/-/-`;
          }
          break;
      }
    });

    this.wrapper.querySelector('.pz-search-vc__button--prev').addEventListener('click', () => {
      event.preventDefault();

      let speciality_id = this.wrapper.querySelector('#speciality_id');
      let date = this.wrapper.querySelector('#date');
      let hour = this.wrapper.querySelector('#hour');

      this.step = this.step - 1;

      switch (this.step) {
        case 0:
          this.prev.style.display = 'none';
          speciality_id.parentElement.style.display = 'flex';
          date.parentElement.style.display = 'none';
          break;
        case 1:
          date.parentElement.style.display = 'flex';
          hour.parentElement.style.display = 'none';
          break;
      }
    });
  }
}
