// we keep this polyfill here since it's needed for Safari too
// while all the other polyfills should be safe to import on ie only
import smoothscroll from 'smoothscroll-polyfill';

import lazySizes from 'lazysizes/lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'script-loader!fitvids/dist/fitvids.min';
import 'script-loader!object-fit-images/dist/ofi.min';
import StickySidebar from 'sticky_sidebar';
import Instafeed from 'instafeed.js';
import Rails from 'rails-ujs';

import "@fortawesome/fontawesome-free/js/all";
import '@fortawesome/fontawesome-free/css/all';

import { parse, format } from 'date-fns';

Rails.start();
/* ================================================= */

/* Application ===================================== */
import 'script-loader!./components/select.js';
import { AccordionRow } from './components/accordionRow.js';
import { AccountDropdown } from './components/accountDropdown.js';
import { AdManager } from './components/ad_manager.js';
import { AnchorsHandler } from './components/anchorsHandler.js';
import { AutoCompleteCities } from './components/autoCompleteCities';
import { Badge } from './components/badge.js';
import { BookVcSidebarCard } from './components/bookVcSidebarCard.js';
import { Chat } from './components/chat.js';
import { CityUpdater } from './components/cityUpdater.js';
import { Collapsible } from './components/collapsible.js';
import { CollapsibleCard } from './components/collapsibleCard.js';
import { Cookie } from './components/cookie.js';
import { DoctorCard } from './components/doctorCard.js';
import { DropdownMenu } from './components/dropdownMenu.js';
import { DropdownMobileMenu } from './components/dropdownMobileMenu.js';
import { DrugMonography } from './components/drugMonography.js';
import { Faqs } from './components/faqs.js';
import { Filters } from './components/filters.js';
import { Flash } from './components/flash.js';
import { Gtag } from './components/google_analytics.js';
import { HealthFacilityMap } from './components/healthFacilityMap.js';
import { Input } from './components/input.js';
// import { MegaMenu } from './components/megaMenu.js'; // Removed during Redesign 2024
import { Modal } from './components/modal.js';
import { PaymentButton } from './components/paymentButton.js';
// import { PostToc } from './components/postToc.js';
import { Previewable } from './components/previewable.js';
import { Rating } from './components/rating.js';
import { RegistrationPage } from './components/registrationPage.js';
import { RegistrationFormWithTrackings } from './components/registrationFormWithTrackings.js';
import { RiskBanner } from './components/riskBanner';
import { SearchDoctor } from './components/searchDoctor.js';
import { SearchVc } from './components/searchVc.js';
// import { SearchNav } from './components/searchNav.js'; // Removed during Redesign 2024
import { Specialities } from './components/specialities.js';
import { StickyHeader } from './components/stickyHeader.js';
import { Thankable } from './components/thankable.js';
import { Trackable } from './components/trackable.js';
import { RelatedContent } from './components/relatedContent';
import { User } from './components/user.js';

/* Application Redesign 2024 ======================= */
import { HeaderSearch } from './components/redesign2024/headerSearch.js';
import { HeaderMenu } from './components/redesign2024/headerMenu.js';

export default class App {
  constructor() {
    /* Application ===================================== */
    this.AccordionRow = new AccordionRow();
    this.AccountDropdown = new AccountDropdown();
    this.AdManager = new AdManager();
    this.AnchorsHandler = new AnchorsHandler();
    this.AutoCompleteCities = new AutoCompleteCities();
    this.Badge = new Badge();
    this.BookVcSidebarCard = new BookVcSidebarCard();
    this.Chat = new Chat();
    this.CityUpdater = new CityUpdater();
    this.Collapsible = new Collapsible();
    this.CollapsibleCard = new CollapsibleCard();
    this.DoctorCard = new DoctorCard();
    this.DropdownMenu = new DropdownMenu();
    this.DropdownMobileMenu = new DropdownMobileMenu();
    this.DrugMonography = new DrugMonography();
    this.Faqs = new Faqs();
    this.Filters = new Filters();
    this.Flash = new Flash();
    this.Gtag = new Gtag();
    this.Input = new Input();
    // this.MegaMenu = new MegaMenu(); // Removed during Redesign 2024
    this.Modal = new Modal();
    this.PaymentButton = new PaymentButton();
    // this.PostToc = new PostToc();
    this.Previewable = new Previewable();
    this.Rating = new Rating();
    this.RegistrationFormWithTrackings = new RegistrationFormWithTrackings(new Cookie());
    this.RegistrationPage = new RegistrationPage();
    this.RiskBanner = new RiskBanner();
    this.SearchDoctor = new SearchDoctor();
    this.SearchVc = new SearchVc();
    // this.SearchNav = new SearchNav(); // Removed during Redesign 2024
    this.Specialities = new Specialities();
    this.StickyHeader = new StickyHeader();
    this.Thankable = new Thankable();
    this.Trackable = new Trackable(new Cookie(), Rails, new User());
    this.RelatedContent = new RelatedContent(Rails, new Cookie());
    this.HealthFacilityMap = new HealthFacilityMap();
    this.parseDate = parse;
    this.formatDate = format;

    /* Application Redesign 2024 ======================= */
    this.HeaderSearch = new HeaderSearch();
    this.HeaderMenu = new HeaderMenu();
  }
}

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import 'swiper/swiper-bundle.css';
import Carousel from 'stimulus-carousel';

const application = Application.start();
const context = require.context('controllers', true, /\.js$/);

application.load(definitionsFromContext(context));
application.register('carousel', Carousel);

window.addEventListener('load', () => {
  const instaWrapper = document.querySelector('.pz-instagram');
  if (instaWrapper) {
    const accessToken = instaWrapper.dataset.token;
    if (accessToken) {
      new Instafeed({
        accessToken: accessToken,
        limit: 5,
        template: '<a class="pz-instagram__item" target="_blank" href="{{link}}"><img title="{{caption}}" src="{{image}}"><div class="pz-instagram__item-icon"></div></a>'
      }).run(); 
    }
  }

  smoothscroll.polyfill();
  objectFitImages();
  window.App = new App();
});
