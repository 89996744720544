export class Specialities {
  constructor() {
    this.form = document.querySelector('.specialities');
    console.log('Component Specialities', !!this.form);

    this.init();
  }

  init() {
    if (this.form) {
      let tabs = document.querySelectorAll(".tabs li");

      const toggleTabs = (event) => {
        const target = event.currentTarget;
        target.classList.contains("veterinario") ? showVets() : showDoctors();
      };


      tabs.forEach((el) => el.addEventListener("click", toggleTabs));


      const showVets = () => {
        document.querySelector(".list-doctors").style.display = "none";
        document.querySelector(".list-vets").style.display = "";
      };

      const showDoctors = () => {
        document.querySelector(".list-doctors").style.display = "";
        document.querySelector(".list-vets").style.display = "none";
      };

      let url = new URL(document.location);
      let params = url.hash;
      (params == "#pets") ? showVets() : showDoctors();
    }
  }
}
