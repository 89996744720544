export class Faqs {
  constructor() {
    this.faqsContainers = document.querySelectorAll('.pz-faqs');

    console.log('Component Faqs', !!this.faqsContainers.length);

    this.init();
  }

  init() {
    for (let faqsContainer of this.faqsContainers) {
      let faqRows = faqsContainer.getElementsByClassName('pz-faqs--faq');

      for (let faqRow of faqRows) {
        faqRow.addEventListener('click', function () {
          this.classList.toggle('pz-faqs--faq__active');
          var panel = this.nextElementSibling;
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
          } else {
            panel.style.maxHeight = panel.scrollHeight + 'px';
          }
        });
      }
    }
  }
}
