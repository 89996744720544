import { GoogleMap } from '@googlemaps/map-loader';

const googleMapApiKey = 'AIzaSyBxZHKO37Ki5zUFFpo2FzUygjhBU-0pFcc';

export class HealthFacilityMap {
  constructor() {
    const railsEnv = document.body.dataset.env;
    if (railsEnv == 'production' || railsEnv == 'staging') {
      let map = document.querySelector('#map_canvas');
      console.log('Component HealthFacilityMap', !!map);

      this.init(map);
    } else {
      console.log(
        'Component HealthFacilityMap is only enabled in production and staging env. Current env is',
        document.body.dataset.env
      );
    }
  }

  init(map) {
    if (map) {
      const lat = parseFloat(map.dataset.latitude);
      const lng = parseFloat(map.dataset.longitude);
      const mapLoader = new GoogleMap();
      mapLoader
        .initMap({
          apiKey: googleMapApiKey,
          divId: 'map_canvas',
          mapOptions: {
            center: { lat: lat, lng: lng },
            zoom: 12,
          },
          apiOptions: { language: 'it', region: 'it', libraries: ['drawing'] },
        })
        .then((googleMap) => {
          var marker = new google.maps.Marker({
            position: { lat, lng },
            title: 'Struttura sanitaria',
          });
          marker.setMap(googleMap);
        });
    }
  }
}
