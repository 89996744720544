import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['birthDate', 'benessere', 'alimentazione', 'salute', 'firstName', 'submitButton', 'lastName', 'email', 'gender', 'password', 'privacyPolicy'];

  validateForm() {
    if (this.allFieldsHaveValue()) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  allFieldsHaveValue() {
    const firstName = this.firstNameTarget.value;
    const lastName = this.lastNameTarget.value;
    const email = this.emailTarget.value;
    const password = this.passwordTarget.value;
    const privacyPolicy = this.privacyPolicyTarget;
    const birthDateFieldIsValid = (
      (this.hasBirthDateTarget && this.birthDateTarget.value) || (!this.hasBirthDateTarget)
    );
    const genderFieldIsValid = (
      (this.hasGenderTarget && this.genderTarget.value) || (!this.hasGenderTarget)
    );
    const oneInterestIsSelectedIfPresent = (
      ((this.hasBenessereTarget && this.benessereTarget.checked) || (this.hasSaluteTarget && this.saluteTarget.checked) || (this.hasAlimentazioneTarget && this.alimentazioneTarget.checked)) ||
      (!this.hasBenessereTarget|| !this.hasSaluteTarget || !this.hasAlimentazioneTarget)
    );

    return (
      firstName != null &&
      firstName != '' &&
      lastName != null &&
      lastName != '' &&
      email != null &&
      email != '' &&
      password != null &&
      password != '' &&
      privacyPolicy.checked &&
      birthDateFieldIsValid &&
      genderFieldIsValid &&
      oneInterestIsSelectedIfPresent
    );
  }

  enableButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableButton() {
    this.submitButtonTarget.disabled = true;
  }
}
