import Flatpickr from "stimulus-flatpickr";
import Rails from "rails-ujs";

// import a theme (could be in your main CSS entry too...)
// import "flatpickr/dist/themes/dark.css";

// import the translation files and create a translation mapping
import { Italian } from "flatpickr/dist/l10n/it.js";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  connect() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      locale: Italian,
      altInput: true,
      altFormat: 'j F Y',
      showMonths: 1
    };

    super.connect();
  }
}
