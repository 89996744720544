export class CityUpdater {
  run(element, targetId) {
    console.log('Component CityUpdater', element, targetId);

    fetch(`/api/v2/cities?province_id=${element.value}&paginate=false`)
      .then((res) => res.json())
      .then((response) => {
        if (response.status == 'success') {
          let target = document.getElementById(targetId);
          target.options.length = 1;

          for (let index in response.cities) {
            target.options[parseInt(index) + 1] = new Option(response.cities[index].name, response.cities[index].id);
          }

          window.respawnSelect(target.parentNode);
        }
      })
      .catch((error) => console.error(error));
  }
}
