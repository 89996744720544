export class CollapsibleCard {
  constructor() {
    this.collapsibleCards = document.querySelectorAll('.pz-card--collapsible');

    console.log('Component CollapsibleCard', !!this.collapsibleCards.length);

    this.init();
  }

  init() {
    for (let card of this.collapsibleCards) {
      card.querySelector('.pz-card--sidebar__header').addEventListener('click', (e) => {
        e.preventDefault();
        let el = e.currentTarget;
        let parent = el.parentElement;

        if (parent.classList.contains('pz-card--collapsed')) {
          parent.classList.remove('pz-card--collapsed');
        } else {
          parent.classList.add('pz-card--collapsed');
        }
      });
    }
  }
}
