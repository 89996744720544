import MicroModal from 'micromodal';
import flatpickr from 'flatpickr';
import { Gtag } from './google_analytics.js';

export class Modal {
  constructor() {
    this.triggers = document.querySelectorAll('.pz-modal-trigger');

    console.log('Component Modal', !!this.triggers.length);

    this.init();
  }

  refresh() {
    this.triggers = document.querySelectorAll('.pz-modal-trigger');
    this.init();
  }

  init() {
    for (let trigger of this.triggers) {
      const autoOpenTrigger = trigger.classList.contains('auto_open');
      const timeoutTrigger = trigger.dataset.timeout;
      const closeTimeoutTrigger = trigger.dataset.closeTimeout;
      const isNotClosableTrigger = trigger.dataset.isNotClosable;

      trigger.addEventListener('click', (e) => {
        // TODO disable modal trigger on click
        // and re-enable after successful modal show
        e.preventDefault();
        this.show(trigger);
      });

      /**
       * This double condition if + else if
       * is needed because there are trigger with just the class
       * and trigger with both class + data attribute
       */
      if (autoOpenTrigger && timeoutTrigger) {
        const timeout = timeoutTrigger || 0;

        setTimeout(() => {
          this.show(trigger);

          // prevent Escape keydown while countdown is still going
          if (closeTimeoutTrigger) {
            const closeTimeout = closeTimeoutTrigger || 0;
            let secondsLeft = closeTimeout / 1000;

            let countdownSeconds = setInterval(() => {
              if (secondsLeft <= 0) {
                clearInterval(countdownSeconds);
              }
              secondsLeft -= 1;
            }, 1000);

            document.addEventListener('keydown', (e) => {
              if (e.key == 'Escape' && secondsLeft >= 0) {
                e.stopImmediatePropagation();
              }
            });
          }
        }, timeout);
      } else if (autoOpenTrigger) {
        this.show(trigger);
      }

      // prevent Escape keydown on modal that shouldn't be closable
      if (isNotClosableTrigger) {
        document.addEventListener('keydown', (e) => {
          if (e.key == 'Escape') {
            e.stopImmediatePropagation();
          }
        });
      }
    }
  }

  selectRespawn(modalId) {
    const pzSelect = document.querySelector(`#${modalId} .pz-select select`);

    if (pzSelect) {
      const select = pzSelect.closest('.pz-select');
      respawnSelect(select);
    }
  }

  datepickerRespawn() {
    let flatpickr_config = {
      dateFormat: 'd/m/Y',
      minDate: '01/01/1900',
      maxDate: window.App.formatDate(new Date(), 'd/M/Y'),
    };

    flatpickr('.flatpickr', flatpickr_config);
  }

  show(trigger) {
    const id = trigger.dataset.id;
    const params = JSON.parse(trigger.dataset.params);

    fetch(
      `/modals/${id}?${Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&')}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        },
      }
    )
      .then((res) => res.text())
      .then((response) => {
        let oldModal = document.getElementById(id);
        let newModal = document.createRange().createContextualFragment(response);
        this.modalTracking = new Gtag();

        if (!!oldModal) {
          oldModal.parentNode.replaceChild(newModal, oldModal);
        } else {
          document.body.append(newModal);
        }

        MicroModal.show(id, {
          disableFocus: true,
          disableScroll: true,
          onClose: (modal) => {
            const modalId = modal.id;
            const triggerSameId = document.querySelector(`[data-id="${modalId}"]`);
            const triggerEventCategory = triggerSameId.dataset.eventCategory;
            const triggerEventName = 'Chiusura modale';

            this.modalTracking.gtagTracking(triggerEventName, triggerEventCategory);

            modal.parentNode.removeChild(modal);
          },
          onShow: (modal) => {
            const modalId = modal.id;
            const triggerSameId = document.querySelector(`[data-id="${modalId}"]`);
            const triggerEventCategory = triggerSameId.dataset.eventCategory;
            const triggerEventName = 'Apertura modale';

            window.App.RegistrationFormWithTrackings.init()

            this.modalTracking.gtagTracking(triggerEventName, triggerEventCategory, undefined, true);

            for (let trigger of this.triggers) {
              const modalClose = document.querySelector('.pz-modal__close');
              const modalOverlay = document.querySelector('.pz-modal__overlay');
              const modalIsNotClosable = trigger.dataset.isNotClosable;
              const overlaysIsBlurred = trigger.dataset.blurOverlay;
              const modalCloseTimeout = trigger.dataset.closeTimeout;

              // if the modal has data-attribute to make it blur,
              // add class u-blur
              if (overlaysIsBlurred) {
                modalOverlay.classList.add('u-blur');
              }

              // if the modal has data-attribute to make it not closable
              // remove attributes + add classes
              if (modalIsNotClosable) {
                modalClose.removeAttribute('data-micromodal-close');
                modalOverlay.removeAttribute('data-micromodal-close');
                modalClose.classList.add('u-hidden');

                // const select = document.querySelector('#user_user_profile_attributes_sex').closest('.pz-select');
                // respawnSelect(select);

                this.selectRespawn(modalId);
                this.datepickerRespawn();
              }

              // if the modal has data-attribute to make it close on X seconds
              // add logic to manage the countdown label + its state
              if (modalCloseTimeout) {
                const modalCountdown = document.querySelector('.pz-modal__countdown');
                const closeTimeout = modalCloseTimeout || 0;
                let secondsLeft = closeTimeout / 1000;

                // remove attributes that make elements closable
                modalClose.removeAttribute('data-micromodal-close');
                modalOverlay.removeAttribute('data-micromodal-close');

                // hide X from the modal
                modalClose.classList.add('u-hidden');

                // show the countdown in the modal
                modalCountdown.classList.remove('u-hidden');

                // respawnSelect
                this.selectRespawn(modalId);
                this.datepickerRespawn();

                // update the countdown label with seconds left
                let enableCloseModalTimeout = setInterval(function () {
                  if (secondsLeft <= 0) {
                    // if seconds left are less/equal to 0
                    // * clear interval
                    // * show the X from modalClose
                    // * set again closable elements as such
                    // * remove text countdown

                    clearInterval(enableCloseModalTimeout);
                    modalClose.classList.remove('u-hidden');
                    modalClose.setAttribute('data-micromodal-close', '');
                    modalOverlay.setAttribute('data-micromodal-close', '');
                    modalCountdown.innerHTML = '&nbsp';
                  } else {
                    // if seconds left are more than 0
                    // update the seconds shown in the countdown

                    modalCountdown.innerHTML = `Potrai chiudere questa finestra tra ${secondsLeft} secondi`;
                  }
                  secondsLeft -= 1;
                }, 1000);
              }
            }
          },
        });
      })
      .catch((error) => console.error(error));
  }
}
