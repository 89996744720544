import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['firstName', 'lastName','phoneNumber', 'startDatetime', 'email', 'password', 'submitButton', 'visitType', 'healthFacility', 'privacyPolicy'];

  validateForm() {
    if (this.allFieldsHaveValue()) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  allFieldsHaveValue() {
    const firstName = this.firstNameTarget.value;
    const lastName = this.lastNameTarget.value;
    const phoneNumber = this.phoneNumberTarget.value;
    const startDatetime = this.startDatetimeTarget.value;
    const healthFacility = this.healthFacilityTarget.value;
    const visitType = this.visitTypeTarget.value;
    const emailFieldIsValid = (
      (this.hasEmailTarget && this.emailTarget.value) || (!this.hasEmailTarget)
    );
    const passwordFieldIsValid = (
      (this.hasPasswordTarget && this.passwordTarget.value) || (!this.hasPasswordTarget)
    );
    const privacyPolicyFieldIsValid = (
      (this.hasPrivacyPolicyTarget && this.privacyPolicyTarget.checked) || (!this.hasPrivacyPolicyTarget)
    );

    return (
      firstName != null &&
      firstName != '' &&
      lastName != null &&
      lastName != '' &&
      phoneNumber != null &&
      phoneNumber != '' &&
      startDatetime != null &&
      startDatetime != '' &&
      healthFacility != null &&
      healthFacility != '' &&
      visitType != null &&
      visitType != '' &&
      emailFieldIsValid &&
      passwordFieldIsValid &&
      privacyPolicyFieldIsValid
    );
  }

  enableButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableButton() {
    this.submitButtonTarget.disabled = true;
  }
}
