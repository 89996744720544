export class StickyHeader {
  constructor() {
    // this.sticky = false;
    // this.navbar = document.querySelector('.pz-navbar');
    // this.skinBanner = document.getElementById('adm-skin-banner');
    // this.init();
  }


  getDistance() {
    return this.navbar.offsetTop;
  }

  setSticky() {
    document.body.style.paddingTop = this.navbar.clientHeight + 'px';
    this.navbar.classList.add('pz-navbar--sticky');
    this.sticky = true;
  }

  unsetSticky() {
    document.body.style.paddingTop = 0;
    this.navbar.classList.remove('pz-navbar--sticky');
    this.sticky = false;
  }

  checkSticky(skinYOffset = 120) {
    let stickyPoint = this.getDistance();
    let offset = window.pageYOffset;
    let distance = stickyPoint - offset;

    if (distance <= 0 && !this.sticky) {
      this.setSticky();
    } else if (this.sticky && offset <= stickyPoint) {
      this.unsetSticky();
    }

    // Dynamically set ad skin position
    if (offset <= 40) {
      if (typeof(this.skinBanner) != 'undefined' && this.skinBanner != null)
        this.skinBanner.style.top = (skinYOffset - offset) + 'px';
    }
  }

  init() {
    if (null == this.navbar) return;
    this.checkSticky();
    window.addEventListener('scroll', () => this.checkSticky());
  }
}
