import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['originField', 'pazientiUserField', 'uwellUserField'];

  connect() {
    this.origin = this.originFieldTarget.value;
    this.showUserSearchField(false);
  }

  changeOrigin() {
    this.origin = event.target.value;
    this.showUserSearchField(true);
  }

  showUserSearchField(reset = null) {
    if (this.origin == 'uwell') {
      this.pazientiUserFieldTarget.style.display = 'none';
      this.setDisabledOnFieldsIn(this.pazientiUserFieldTarget, true);
      this.uwellUserFieldTarget.style.display = 'block';
      this.setDisabledOnFieldsIn(this.uwellUserFieldTarget, false);
    } else {
      this.uwellUserFieldTarget.style.display = 'none';
      this.setDisabledOnFieldsIn(this.uwellUserFieldTarget, true);
      this.pazientiUserFieldTarget.style.display = 'block';
      this.setDisabledOnFieldsIn(this.pazientiUserFieldTarget, false);
    }

    if (reset) {
      jQuery(this.uwellUserFieldTarget).find('input').last().tokenInput('clear');
      jQuery(this.pazientiUserFieldTarget).find('input').last().tokenInput('clear');
    }
  }

  setDisabledOnFieldsIn(element, disabled = true) {
    let fields = element.getElementsByTagName('input');
    for (const field of fields) {
      field.disabled = disabled;
    }
  }
}
