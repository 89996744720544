import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'input', 'output'
  ]

  update = () => {
    this.outputTarget.value = this.inputTarget.value
  }
}
