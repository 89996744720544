export class Flash {
  set(key, value) {
    let wrapper = document.querySelector('.pz-flash');
    wrapper.prepend(this.template(key, value));
  }

  template(key, value) {
    return document.createRange().createContextualFragment(
      `<div class="pz-flash__item ${key}">
         <div class="pz-flash__inner">
           <span class="pz-flash__value">${value}</span>
           <i class="pz-flash__close fas fa-times" onclick="this.parentElement.parentElement.remove()"></i>
         </div>
       </div>`
    );
  }
}
