export class RegistrationFormWithTrackings {
  constructor(cookies) {
    this.cookies = cookies
    console.log('Component RegistrationFormWithTrackings')
  }

  init() {
    let registrationForms = document.querySelectorAll('.j-registration-form-with-trackings');
    console.log(registrationForms);
    for (let registrationForm of registrationForms) {
      if (this.userHasGivenConsent()) {
        this.addTrackablesToForm(registrationForm);
      }
    }
  }

  addTrackablesToForm(form) {
    const latestSeenPostIds = JSON.parse(this.cookies.get('trackings-Post') || '[]')
    const latestSeenContentIds = JSON.parse(this.cookies.get('trackings-Content') || '[]')
    const latestSeenQuestionIds = JSON.parse(this.cookies.get('trackings-Question') || '[]')

    for (let postId of latestSeenPostIds) {
      this.appendResourceToForm(form, "Post", postId);
    }

    for (let contentId of latestSeenContentIds) {
      this.appendResourceToForm(form, "Content", contentId);
    }

    for (let questionId of latestSeenQuestionIds) {
      this.appendResourceToForm(form, "Question", questionId);
    }
  }

  appendResourceToForm(form, resourceType, resourceId) {
    var trackableTypeInput = document.createElement("input");
    trackableTypeInput.type = "hidden";
    trackableTypeInput.name = "user[trackings_attributes][][resource_type]";
    trackableTypeInput.value = resourceType;
    form.appendChild(trackableTypeInput);

    var trackableIdInput = document.createElement("input");
    trackableIdInput.type = "hidden";
    trackableIdInput.name = "user[trackings_attributes][][resource_id]";
    trackableIdInput.value = resourceId;
    form.appendChild(trackableIdInput);
  }

  userHasGivenConsent() {
    const TRACKING_AND_ADV_CONSENT_CATEGORY = 5
    return !(
      _iub.cs === undefined ||
      _iub.cs.consent.timestamp === undefined ||
      _iub.cs.consent.purposes[TRACKING_AND_ADV_CONSENT_CATEGORY] === undefined
    ) 
  }
}
