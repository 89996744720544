export class Gtag {
  constructor() {
    this.pageView();
    setInterval(this.pageView, 1000 * 90);
  }

  /**
   * Generic function to track specific events
   * in Google Analytics
   * @param {string} eventName - name of the event shown in Analytics
   * @param {string} eventCategory - name of the macro category shown in Analytics
   * @param {string} eventLabel - label of the event shown in Analytics
   * @param {boolean} notInteraction - add non-interaction event
   */
  gtagTracking(eventName, eventCategory, eventLabel = window.location.href, notInteraction = false) {
    if (eventName && eventCategory) {
      console.log(`tracking generic event w/: event name ${eventName} and category ${eventCategory}`);
      gtag('event', eventName, {
        event_category: eventCategory,
        event_label: eventLabel,
        non_interaction: notInteraction
      });
    }
  }

  /**
   * Function to track submit form
   */
  submitForm() {
    const formGtag = document.querySelectorAll('.j-gtag-submit');

    if (formGtag) {
      for (let form of formGtag) {
        const formEventName = form.dataset.eventName;
        const formEventCategory = form.dataset.eventCategory;
        this.gtagTracking(formEventName, formEventCategory);
      }
    }
  }

  /**
   * Function to track page view
   */
  pageView() {
    const viewPageGtag = document.querySelector('.j-gtag-view');
    const viewPageEventName = 'Pagina visualizzata';

    if (viewPageGtag) {
      const viewPageEventCategory = viewPageGtag.dataset.eventCategory;
      this.gtagTracking(viewPageEventName, viewPageEventCategory);
    }
  }
}
