import { Flash } from './flash.js';

export class Thankable {
  constructor() {
    this.thankables = document.querySelectorAll('.pz-thankable__button');

    console.log('Component Thankable', !!this.thankables.length);

    this.init();
  }

  init() {
    for (let thankable of this.thankables) {
      thankable.onclick = (e) => {
        this.sayThankYou(thankable);
      };
    }
  }

  sayThankYou(thankable) {
    let type = thankable.getAttribute('thankable');
    let slug = thankable.getAttribute('slug');
    let base_path;

    switch (type) {
      case 'content':
        base_path = 'video';
        break;
      case 'question':
        base_path = 'risposte';
        break;
      default:
        return false;
    }

    fetch(`/${base_path}/${slug}/thanks`, {
      method: 'POST',
      body: JSON.stringify({
        type: type,
        slug: slug,
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        let flash = new Flash();

        if ('error' == response.status || 'fail' == response.status) {
          flash.set('error', response.message);
          return false;
        }

        if ('success' == response.status) {
          flash.set('success', response.message);
        }

        let buttonReplace = document
          .createRange()
          .createContextualFragment(
            '<button class="button button--link pz-thankable__thanked"><span class="button__icon pazienti_icon pazienti_icon-like animated swing"></span></button>'
          );
        thankable.parentNode.insertBefore(buttonReplace, thankable);
        thankable.remove();
      })
      .catch((error) => console.error(error));
  }
}
