export class RiskBanner {
  constructor() {
    this.disclaimerList = document.querySelector('.pz-disclaimer-list');

    console.log('Component RiskBanner', this.disclaimerList?.dataset?.showRiskWarning == 'true');

    if (this.disclaimerList?.dataset?.showRiskWarning == 'true') {
      this.url = this.disclaimerList.dataset.url;
      this.acceptance_id = this.disclaimerList.dataset.acceptanceId;
      this.openBanner();
    }
  }

  openBanner() {
    let banner = document.createRange().createContextualFragment(`
      <div class="pz-disclaimer pz-disclaimer--gdpr">
        <div class="pz-disclaimer__text">
          <div>Le informazioni proposte in questo sito non sono un consulto medico. In nessun caso, queste informazioni sostituiscono un consulto, una visita o una diagnosi formulata dal medico. Non si devono considerare le informazioni disponibili come suggerimenti per la formulazione di una diagnosi, la determinazione di un trattamento o l’assunzione o sospensione di un farmaco senza prima consultare un medico di medicina generale o uno specialista. Per ulteriori informazioni clicca <a href="/avvertenza-di-rischio">qui</a>. Chiudendo questo banner o accedendo a un qualunque elemento sottostante questo banner acconsenti all’uso dei cookie.</div>
        </div>
        <button class="pz-disclaimer__button button button--medium button--secondary" onclick="window.App.RiskBanner.accept(this)">OK</button></div>
      </div>
    `);

    document.querySelector('.pz-disclaimer-list').appendChild(banner);
  }

  accept(el) {
    fetch(window.App.RiskBanner.url, {
      method: 'POST',
      body: JSON.stringify({
        user: {
          acceptance_ids: [window.App.RiskBanner.acceptance_id],
        },
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status == 'success') {
          this.closeBanner(el.parentNode);
        }
      })
      .catch((error) => console.error(error));
  }

  closeBanner(banner) {
    banner.remove();
    window.location.reload();
  }
}
