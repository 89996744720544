import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['doctorFields', 'roleField'];

  connect() {
    this.roles_mask = this.roleFieldTarget.value;
    this.updateActiveFields();
  }

  changeActiveField() {
    this.roles_mask = event.target.value;
    this.updateActiveFields();
  }

  updateActiveFields() {
    if (this.roles_mask == 8) {
      this.doctorFieldsTarget.style.display = 'block';
      this.doctorFieldsTarget.disabled = false;
    } else {
      this.doctorFieldsTarget.style.display = 'none';
      this.doctorFieldsTarget.disabled = true;
    }
  }
}
