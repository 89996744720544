import { Controller } from '@hotwired/stimulus'
import get from 'lodash/get'

export default class extends Controller {
  static targets = ['category', 'topicSelect']

  categoryChanged(evt) {
    const label = this.#section()
    if (evt.target.dataset.section != label) {
      const choice = !evt.target.dataset.section || confirm('Il cambio di categoria appartenete a sezione diversa comporta la rimozione di tutti i topics. Confermi?')
      if (choice) {
        evt.target.dataset.section = label
        this.topicSelectTargets.forEach(this.#addSectionToUri(label))
        document.getElementById('nav-topics-tab').classList.remove('disabled')
      } else {
        evt.target.selectedIndex = [...evt.target.options].findIndex(({value}) => value == evt.target.dataset.id)
        return
      }
    }
    evt.target.dataset.id = evt.target.selectedIndex
  }

  #addSectionToUri = label => select => {
    // remove tokenInput without section search param
    const clone = select.cloneNode()
    const parent = select.parentElement
    parent.removeChild(parent.getElementsByTagName('ul')[0])
    parent.removeChild(select)
    select = parent.appendChild(clone)

    const url = new URL(select.dataset.uri, window.location.href)
    url.searchParams.set('section_name_eq', label)
    select.dataset.uri = url.pathname + url.search

    // see app/assets/javascripts/admin/tokeninput.js
    
    delete select.dataset.pre
    
    const { uri, placeholder, hintText, allowFreeTagging: freeTagging, propertyToSearch: propertyToSearch = 'name' } = select.dataset

    $(select).tokenInput(uri, {
      allowFreeTagging,
      crossDomain: false,
      hintText,
      minChars: 3,
      placeholder,
      propertyToSearch,
      theme: 'facebook',
      zindex: 9999
    })
  }

  #section() {
    return get(this, 'categoryTarget.selectedOptions.0.parentElement.label')
  }
}
