export class Chat {
  constructor() {
    this.chat = undefined;
    this.chatTrigger = undefined;

    this.init();
  }

  init() {
    this.chatTrigger = document.querySelector('.pz-chat');

    if (!this.chatTrigger) {
      return false;
    }

    this.chatTrigger.classList.remove('u-hidden');

    this.chatTrigger.addEventListener('click', () => {
      this.chatTrigger.classList.add('rotating');

      setTimeout(() => {
        this.chatTrigger.style.display = 'none';
      }, 2000);

      this.injectScript();
    });

    this.openAutomatically();
  }

  injectScript() {
    var that = this;

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.load = () => {
        that.chat = window.$zopim;
        that.chat.livechat.set({
          language: 'it',
          name: chat_username,
          email: chat_email,
        });
        that.show();
      };
      js.src = '//v2.zopim.com/?y23pTvGSXkePWDJYiAUbPforjsWCKKMo';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'v2-zopim');
  }

  show() {
    return this.chat.livechat.window.show();
  }

  openAutomatically() {
    let pathname = window.location.pathname;
    let qs = document.querySelector("meta[name='openChatAutomatically']");
    let openChatAutomatically = qs === null || qs === undefined ? undefined : qs.dataset['value'];

    if (openChatAutomatically) {
      this.chatTrigger.click();
    }
  }
}
