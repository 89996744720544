export class PaymentButton {
  constructor() {
    this.paymentButtons = document.querySelectorAll('.checkout-button');

    console.log('Component PaymentButton', !!this.paymentButtons.length);

    this.init();
  }

  init() {
    for (let paymentButton of this.paymentButtons) {
      paymentButton.addEventListener('click', function () {
        fetch('/pagamenti', {
          method: 'POST',
          body: JSON.stringify({
            payable_id: paymentButton.dataset.payableId,
            payable_type: paymentButton.dataset.payableType,
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (session) {
            return stripe.redirectToCheckout({ sessionId: session.id });
          })
          .then(function (result) {
            if (result.error) {
              alert(result.error.message);
            }
          })
          .catch(function (error) {
            console.error('Error:', error);
          });
      });
    }
  }
}
