export class DoctorCard {
  constructor() {
    this.doctorCards = document.querySelectorAll('.pz-card--doctor');

    console.log('Component DoctorCard', !!this.doctorCards.length);

    this.init();
  }

  init() {
    for (let card of this.doctorCards) {
      card.doctorId = card.getAttribute('doctor_id');
      card.hasVcButton = card.querySelectorAll('.button--videoconsultation').length > 0;
      if (card.hasVcButton) {
        card.landingPageUriPath = card.getAttribute('landing_page_uri_path');

        fetch(card.landingPageUriPath, {
          method: 'get',
          headers: {
            'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
          },
        })
          .then((res) => res.json())
          .then((response) => {
            if ('success' == response.status) {
              card.querySelector('.button--videoconsultation').setAttribute('href', response.uri);
            } else {
              let button = card.querySelector('.button--videoconsultation');
              button.parentNode.removeChild(button);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }
}
