import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'submitButton',
    'acceptContract'
  ];

  validateForm() {
    if (this.allFieldsHaveValue()) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  allFieldsHaveValue() {
    const acceptContract = this.acceptContractTarget;

    return (
      acceptContract.checked
    );
  }

  enableButton() {
    this.submitButtonTarget.classList.remove("disabled");
  }

  disableButton() {
    this.submitButtonTarget.classList.add("disabled");
  }
}
