import Choices from 'choices.js/public/assets/scripts/choices.min.js';
import { format } from 'date-fns';

export class Input {
  constructor() {
    this.searchInputs = document.querySelectorAll('.pz-input--search input');
    this.dateWithNowButtonInputs = document.querySelectorAll('.dateFieldWithNowButton input');
    this.init();
  }

  init() {
    this.enableSearchInputs();
    this.enableSearchTriggers();
    this.enableDateWithNowButtonInputs();
    this.enableChoices();

    let event = new window.CustomEvent('keyup');
    this.searchInputs.forEach((item) => item.dispatchEvent(event));
  }

  enableSearchInputs() {
    for (let searchInput of this.searchInputs) {
      let arrowIcon = document.createElement('span');
      arrowIcon.className = 'pazienti_icon pazienti_icon-arrow-right';
      arrowIcon.style.display = 'none';
      searchInput.parentElement.appendChild(arrowIcon);

      searchInput.addEventListener('keyup', () => {
        let target = event.currentTarget;
        let parentElement = target.parentElement;

       target.value.length > 0 ? parentElement.classList.add('pz-input--active') : parentElement.classList.remove('pz-input--active');
      });
    }
  }

  enableDateWithNowButtonInputs() {
    for (let dateInput of this.dateWithNowButtonInputs) {
      let inputGroup = dateInput.closest('.input-group');
      let nowButton = inputGroup.querySelector('.btnDateNow');
      console.log(nowButton);
      nowButton.addEventListener('click', (e) => {
        let dateFields = e.target.closest('.input-group').getElementsByClassName('dateField');
        for (let dateField of dateFields) {
          dateField.value = format(new Date(), 'd-M-y H:mm');
        }
      });
    }
  }

  enableSearchTriggers() {
    let searchTriggers = document.querySelectorAll('.pz-input--search .pazienti_icon-arrow-right');

    for (let searchTrigger of searchTriggers) {
      searchTrigger.addEventListener('click', () => {
        let target = event.currentTarget;
        target.closest('form').submit();
      });
    }
  }

  enableChoices() {
    if (null == document.querySelector('.js-choice')) {
      return false;
    }

    const choices = new Choices('.js-choice', {
      loadingText: 'Caricando...',
      noResultsText: 'Nessun risultato trovato',
      noChoicesText: 'Nessuna opzione da selezionare',
      itemSelectText: '',
      searchPlaceholderValue: 'Filtra le opzioni',
      classNames: {
        containerOuter: 'choices',
        containerInner: 'choices__inner',
        input: 'choices__input',
        inputCloned: 'choices__input--cloned',
        list: 'choices__list',
        listItems: 'choices__list--multiple',
        listSingle: 'choices__list--single',
        listDropdown: 'choices__list--dropdown',
        item: 'choices__item',
        itemSelectable: 'choices__item--selectable',
        itemDisabled: 'choices__item--disabled',
        itemChoice: 'choices__item--choice',
        placeholder: 'choices__placeholder',
        group: 'choices__group',
        groupHeading: 'choices__heading',
        button: 'choices__button',
        activeState: 'is-active',
        focusState: 'is-focused',
        openState: 'is-open',
        disabledState: 'is-disabled',
        highlightedState: 'is-highlighted',
        flippedState: 'is-flipped',
        loadingState: 'is-loading',
        noResults: 'has-no-results',
        noChoices: 'has-no-choices',
      },
      fuseOptions: {
        include: 'score',
      },
    });
  }
}
