export class AdManager {
  constructor() {
    this.init();
  }

  init() {
    const section = document.body.getAttribute('data-section');
    const category = document.body.getAttribute('data-category');
    const campaigns = (document.body.getAttribute('data-campaign-list') || "").split(',');
    const topics = (document.body.getAttribute('data-topic-list') || "").split(',');

    window.googletag = window.googletag || {cmd: []};
    googletag.cmd.push(() => {
      googletag.pubads().
        setTargeting('sec', section).
        setTargeting('subsec', category).
        setTargeting('camp', campaigns).
        setTargeting('topics', topics);

      googletag.enableServices();
    });

    const scriptUrl = document.body.getAttribute('data-adm-script-url');
    $.getScript(scriptUrl);
  }

  initPersonalAdm() {
    // const ads = document.querySelectorAll('.ads');
    // const env = document.body.getAttribute('data-env');
    // const section = document.body.getAttribute('data-section');
    // const category = document.body.getAttribute('data-category');
    // const micro = (document.body.getAttribute('data-micro-list') || "").split(',');
    // const admEnv = env === 'production' ? 'production' : 'staging';

    // googletag.cmd.push(() => {
    //   ads.forEach((ad) => {
    //     const unitId = ad.getAttribute('data-unit-id');
    //     switch (unitId) {
    //       case 'pazienti/sidebar_top':
    //         googletag.defineSlot(`/22835580655/${unitId}`, [300, 250], ad.id).addService(googletag.pubads());
    //         break;
    //       case 'pazienti/incontent_1':
    //       case 'pazienti/incontent_2':
    //       case 'pazienti/incontent_3':
    //       case 'pazienti/incontent_4':
    //       case 'pazienti/incontent_5':
    //       case 'pazienti/incontent_6':
    //       case 'pazienti/incontent_7':
    //       case 'pazienti/incontent_8':
    //       case 'pazienti/incontent_9':
    //       case 'pazienti/incontent_10':
    //         googletag.defineSlot(`/22835580655/${unitId}`, ['fluid', [300, 250]], ad.id).addService(googletag.pubads());
    //         break;
    //       case 'pazienti/skin':
    //         const slot = googletag.defineSlot(`/22835580655/${unitId}`, [[1920, 1080], [320, 50]], ad.id).addService(googletag.pubads());
    //         const mapping = googletag.sizeMapping()
    //                                  .addSize([1024, 200], [1920, 1080])
    //                                  .addSize([0, 0], [320, 50])
    //                                  .build();
    //         slot.defineSizeMapping(mapping);
    //         break;
    //       default:
    //         break;
    //     }
    //   });

    //   googletag.pubads().
    //     setTargeting('env', admEnv).
    //     setTargeting('section', section).
    //     setTargeting('category', category).
    //     setTargeting('micro', micro);

    //   googletag.pubads().enableSingleRequest();
    //   googletag.enableServices();


    //   googletag.cmd.push(() => {
    //     ads.forEach((ad) => {
    //       googletag.display(ad.id)
    //     });
    //   });
    // });
  }
}
